import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_GLOBAL_SETTINGS, SET_GLOBAL_SETTINGS, GET_SOCIALSETTINGS, SET_SOCIALSETTINGS } from '../actions';
import { appId, apiUrl, deliveryId, getHeader} from "../components/Helpers/Config";
import cookie from 'react-cookies';
import Axios from 'axios';

export const watchGetSettings = function* () {
  yield takeEvery(GET_GLOBAL_SETTINGS, workerGetSettings);
}

function* workerGetSettings() {
  try {
	var availabilityId = (cookie.load("defaultAvilablityId") === undefined || cookie.load("defaultAvilablityId") === '' )?'':cookie.load("defaultAvilablityId");
	var orderPostalCode = (cookie.load('orderPostalCode') === undefined || cookie.load("orderPostalCode") === '' )?'':cookie.load("orderPostalCode");
	var posCdParm = (orderPostalCode !== '') ? "&postal_code=" + orderPostalCode : '';
	var avltyParm = (availabilityId !== '') ? "&availability=" + availabilityId : '';
    	
    const uri = apiUrl+'settings/get_common_settings?app_id='+appId+avltyParm+posCdParm;
    const result = yield call(Axios.get, uri, getHeader ());
	cookie.save("deliveryOption", "No");

	if (result.data.status === "ok") {

		var resultSet = result.data.result_set;
		availabilityId = (availabilityId !== '') ? availabilityId : resultSet.ninja_pro_default_availability_id;
		var availability = resultSet.availability;
		var availabilityLen = availability.length
		for (var i = 0; i < availabilityLen; i++) {
			if (availability[i].availability_id === deliveryId) {
				cookie.save("deliveryOption", "Yes");
			}

		}
	}
	availabilityId = (availabilityId !== '') ? availabilityId : deliveryId;
	cookie.save("defaultAvilablityId",availabilityId);
	var resultArr = [];
	resultArr.push(result.data);

    yield put({ type: SET_GLOBAL_SETTINGS, value: resultArr });
  } 
  catch (error){
    console.log('Get Settings Failed',error);
  }
} 

export const watchGetSocialsetting = function* () {
	yield takeEvery(GET_SOCIALSETTINGS, watchGetSocialsettingData);
  }

  function* watchGetSocialsettingData() {
	try {
	  const uri = apiUrl+'settings/get_social_settings?app_id='+appId;
	  const result = yield call(Axios.get, uri, getHeader ());
	  var resultArr = [];
	  resultArr.push(result.data);
	  yield put({ type: SET_SOCIALSETTINGS, value: resultArr});	

	}
	catch (error){
	  console.log('Get addon Failed',error);
	}
  }